import { Component, OnInit } from "@angular/core";
import { BookContentListService } from "../book-content-list/book-content-list.service";
import { AllApisService } from "../all-apis.service";
import { Router } from "@angular/router";
import { ConfirmDialogService } from "../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../shared/ytplayer/ytplayer.service";
import { AnonymousSubject } from "rxjs-compat";
import { WindowRefService } from "../payment/window-ref.service";
import { MasterAdminService } from "../master-admin/master-admin.service";
declare var $: any;

@Component({
  selector: "app-teacher-manual",
  templateUrl: "./teacher-manual.component.html",
  styleUrls: ["./teacher-manual.component.css"],
})
export class TeacherManualComponent implements OnInit {
  questionList = [];
  stdList = [];
  categoryList = [];
  seriesList = [];
  subjectList = [];
  allSchool = [];
  chapterNumber: any;
  chapterTitle: any;
  chapterId: any;
  editId: any;
  mainId: any;
  editTitle: any;
  editSequence: any;
  searchObj = new searchKeys();
  popup = false;
  linkList: any;
  deletelesson: boolean = false;
  videoLink: any;
  safeVideo: any;
  checkLink: any;
  editLink: string;
  newLink: string;
  ebooks: any;
  videos: any;
  audios: any;
  editIndex: any;
  lessonIndex: any;
  deleteIndex: any;
  subjectMaps: any;
  sectionList: any[];
  allCategoryList: any;
  allSubjectList: any;
  bookContentKeys = [];
  chapterList = [];
  role: string;
  allowEdit: boolean = false;
  lessonPlan: any;
  answerKey: any;
  chapterKey: any;
  deepLink: any;
  workbook: any;
  workbookKey: any;
  showLoader: boolean = false;
  loaderMessage: string = "";
  fileToUpload: any;
  showErrorMessage: string = "";
  urlToUpdate: string = "";
  selectedBook: any;
  contentType: string = "";
  newContent = {
    mainTitle: "",
    url: "",
    contentType: "",
  };
  constructor(
    private serv: BookContentListService,
    private allApiServ: AllApisService,
    private route: Router,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService,
    public masterAdminService: MasterAdminService
  ) {}

  ngOnInit(): void {
    this.searchObj.session = "2023-24";
    this.searchObj.std = "";
    this.searchObj.category = "";
    this.searchObj.series = "";
    this.searchObj.subject = "";
    this.role = localStorage.getItem("role");
    if (this.role == "Master Admin") {
      this.allowEdit = true;
    } else if (this.role == "Editor") {
      this.allowEdit = true;
    }
    this.getBookContentKeys();
  }

  resetCall() {
    this.searchObj.session = "2023-24";
    this.searchObj.std = "";
    this.searchObj.category = "";
    this.searchObj.series = "";
    this.searchObj.subject = "";
    this.getBookContentKeys();
  }

  resetNewContent() {
    this.newContent = {
      mainTitle: "",
      url: "",
      contentType: "",
    };
    this.contentType = "";
  }

  selectContentType() {
    if (this.contentType == "teacher") {
      this.newContent.mainTitle = "Teacher Manual";
    } else if (this.contentType == "lesson") {
      this.newContent.mainTitle = "Lesson plan";
    } else if (this.contentType == "worksheet") {
      this.newContent.mainTitle = "Worksheet Key";
    } else if (this.contentType == "answer") {
      this.newContent.mainTitle = "Answer key";
    }
  }

  getBookContentKeys() {
    this.serv.bookContentKeys().subscribe((res) => {
      this.bookContentKeys = res.result;
      console.log("this.stdList", this.stdList);
      console.log("keys", res);
      this.bookContentKeys = res.result;
      if (this.bookContentKeys && this.bookContentKeys.length) {
        console.log("bookContentKeys for stdlist", this.bookContentKeys);
        this.stdList = this.bookContentKeys
          .filter((e) => e.session && e.session == this.searchObj.session)
          .map((a) => a.grade);
        this.stdList = [...new Set(this.stdList)];
        console.log("end of bookcontentkeys", this.stdList);
      }
    });
  }

  selectSession() {
    this.searchObj.subject = "";
    this.searchObj.series = "";
    this.searchObj.category = "";
    this.subjectList = [];
    this.getBookContentKeys();
    console.log("came out of this shit");
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.std
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.grade == this.searchObj.std &&
          a.session &&
          a.session == this.searchObj.session
      );
      this.subjectList = filteredKeys.map((e) => e.subject);
      this.subjectList = [...new Set(this.subjectList)];
    }
  }

  updateSubject() {
    this.searchObj.subject = "";
    this.searchObj.series = "";
    this.searchObj.category = "";
    this.subjectList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.std
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.grade == this.searchObj.std &&
          a.session &&
          a.session == this.searchObj.session
      );
      console.log("filteredKeys", filteredKeys);
      this.subjectList = filteredKeys.map((e) => e.subject);
      this.subjectList = [...new Set(this.subjectList)];
    }
  }

  updateCategoryList() {
    this.searchObj.series = "";
    this.searchObj.category = "";
    if (
      this.searchObj.subject &&
      this.allCategoryList &&
      this.allCategoryList.length
    ) {
      this.categoryList = this.allCategoryList.filter(
        (a) => a.subject.toUpperCase() == this.searchObj.subject.toUpperCase()
      );
    }
    this.categoryList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.subject
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) => a.subject == this.searchObj.subject
      );
      this.categoryList = filteredKeys.map((e) => e.category);
      this.categoryList = [...new Set(this.categoryList)];
    }
  }

  updateSeriesList() {
    this.searchObj.series = "";
    this.seriesList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.category
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.category == this.searchObj.category &&
          a.subject.toUpperCase() == this.searchObj.subject.toUpperCase()
      );
      this.seriesList = filteredKeys.map((e) => e.series);
      this.seriesList = [...new Set(this.seriesList)];
    }
  }

  getContentList() {
    if (
      this.searchObj.std &&
      this.searchObj.std != "" &&
      this.searchObj.std != "Select Class"
    ) {
    } else {
      return this.notifier.notify("error", "Please provide class name.");
    }
    if (
      this.searchObj.subject &&
      this.searchObj.subject != "" &&
      this.searchObj.subject != "Select Subject"
    ) {
    } else {
      return this.notifier.notify("error", "Please provide subject name.");
    }
    if (
      this.searchObj.category &&
      this.searchObj.category != "" &&
      this.searchObj.category != "Select Category"
    ) {
    } else {
      return this.notifier.notify("error", "Please provide category name.");
    }
    if (
      this.searchObj.series &&
      this.searchObj.series != "" &&
      this.searchObj.series != "Select Series"
    ) {
    } else {
      return this.notifier.notify("error", "Please provide series name.");
    }
    this.selectedBook = null;
    let self = this;
    this.serv.getBookContents(this.searchObj).subscribe((res) => {
      console.log("content", res);
      if (res.status) {
        this.questionList = res["result"];
        console.log("this.questionList", this.questionList);
        this.chapterList = [];
        if (this.questionList && this.questionList.length) {
          this.questionList.forEach((a) => {
            console.log("a here is ", a);
            if (a.session == this.searchObj.session) {
              this.selectedBook = a;
              console.log("sleected book", this.selectedBook);
            }
          });
          // this.populateTable();
        }
      }
    });
  }

  addManual() {
    if (this.selectedBook.teacherManual) {
    } else {
      this.selectedBook.teacherManual = [];
    }
    $("#uploadBookModel").modal("show");
  }

  saveContent() {
    let self = this;
    if (this.fileToUpload) {
      this.showLoader = true;
      this.loaderMessage = "Uploading file please wait.....";
      (<any>$("#alertModal")).modal("show");
      this.masterAdminService
        .saveContent(this.fileToUpload)
        .subscribe((res) => {
          if (res["status"]) {
            this.showLoader = false;
            (<any>$("#alertModal")).modal("hide");
            this.fileToUpload = null;
            this.showErrorMessage = "";
            let url = res.data.url;
            this.newContent.url = url;
            this.selectedBook.teacherManual.push(this.newContent);
            this.resetNewContent();
            this.doneUploadBook();
          } else {
            this.fileToUpload = null;
            this.notifier.notify("error", "Failed To Upload File Please Retry");
          }
        });
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select a File");
    }
  }

  doneUploadBook() {
    $("#uploadBookModel").modal("hide");
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    console.log("filename", this.fileToUpload);
    if (this.fileToUpload?.name.includes("pdf")) {
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select only pdf file");
    }
  }

  removeFile() {
    this.fileToUpload = null;
  }

  open(url) {
    window.open(url, "_blank");
  }

  updateBookDetails() {
    var self = this;
    this.confirmBox.confirmThis(
      "Are you sure you want to Update the Manual urls",
      "Update Manual Urls ?",
      function () {
        self.serv.updateManual(self.selectedBook).subscribe((res) => {
          if (res.status == true) {
            self.notifier.notify("success", "Manual Updated succesfully");
            // $("#ebookModal").modal("hide");
          }

          self.getContentList();
        });
      },
      function () {}
    );
  }
}

export class searchKeys {
  std: string;
  subject: string;
  category: string;
  series: string;
  session: string;
}
