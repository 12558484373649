import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { AllApisService } from "../all-apis.service";
import { LessonServiceService } from "../lesson-dashboard/lesson-service.service";
@Injectable({
  providedIn: "root",
})
export class SchoolServiceService {
  constructor(
    private http: HttpClient,
    private allApiService: AllApisService,
    public lessonServ: LessonServiceService
  ) {}

  addSchoolDetail(obj: any) {
    return this.http.post<any>(
      this.allApiService.baseurl + "/school/saveSchoolInfo",
      obj,
      this.allApiService.httpOptions
    );
  }

  updateSchoolDetail(obj: any) {
    return this.http.post<any>(
      this.allApiService.baseurl + "/school/updateSchoolInfo",
      obj,
      this.allApiService.httpOptions
    );
  }

  updateSchoolSessionDetail(obj) {
    return this.http.post<any>(
      this.allApiService.baseurl + "/school/updateSchoolSessionInfo",
      obj,
      this.allApiService.httpOptions
    );
  }

  updateSchoolRoyaltyDetail(obj) {
    return this.http.post<any>(
      this.allApiService.baseurl + "/school/updateSchoolRoyaltyDetail",
      obj,
      this.allApiService.httpOptions
    );
  }

  updateSchoolCustomerIdDetail(data: any) {
    return this.http.post<any>(
      this.allApiService.baseurl + "/school/updateSchoolCustomerIdDetail",
      data,
      this.allApiService.httpOptions
    );
  }

  getSchoolList(obj = {}) {
    if (obj["schoolType"] && obj["schoolType"] != "") {
      let params = new HttpParams();
      params = params.append("schoolType", obj["schoolType"]);
      this.allApiService.httpOptions.params = params;
    }
    return this.http.get<any>(
      this.allApiService.baseurl + "/school",
      this.allApiService.httpOptions
    );
  }

  removeSession(smId) {
    let params = new HttpParams();
    params = params.append("smSchoolId", smId);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/removeSession",
      this.allApiService.httpOptions
    );
  }

  getAllSchool() {
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/allSchoolMap",
      this.allApiService.httpOptions
    );
  }

  getAllSchoolRoyalty() {
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/getAllSchoolRoyalty",
      this.allApiService.httpOptions
    );
  }

  getStudentList(name) {
    let params = new HttpParams();
    params = params.append("name", name);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/getStudentList",
      this.allApiService.httpOptions
    );
  }

  getAllSchoolCustomerId(smSchoolId) {
    let params = new HttpParams();
    params = params.append("smSchoolId", smSchoolId);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/getAllSchoolCustomerId",
      this.allApiService.httpOptions
    );
  }

  getSchoolInfo(smSchoolId: any) {
    let params = new HttpParams();
    params = params.append("smSchoolId", smSchoolId);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(
      this.allApiService.baseurl + "/getSchoolInfo",
      this.allApiService.httpOptions
    );
  }
  getSchoolInfoList() {
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/SchoolInfoList",
      this.allApiService.httpOptions
    );
  }
  getIndividualSchoolInfoList() {
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/SchoolInfo",
      this.allApiService.httpOptions
    );
  }
  updateUserAccount(obj: any) {
    return this.http.patch<any>(
      this.allApiService.baseurl + "/school/updateUserAccount",
      obj,
      this.allApiService.httpOptions
    );
  }

  fetchMeetingList(hostEmail: string, selectedDate: any) {
    var obj = {
      hostEmail: hostEmail,
      selectedDate: selectedDate,
    };
    return this.http.post<any>(
      this.allApiService.baseurl + "/school/meetingList",
      obj,
      this.allApiService.httpOptions
    );
  }

  getHostEmailList() {
    return this.http.get<any>(
      this.allApiService.baseurl + "/school/emailList",
      this.allApiService.httpOptions
    );
  }

  addImpartusUser(obj: any) {
    return this.http.post<any>(
      this.allApiService.baseurl + "/impartus/addImpartusUser",
      obj,
      this.allApiService.httpOptions
    );
  }

  deleteImpartusUser(obj: any) {
    return this.http.patch<any>(
      this.allApiService.baseurl + "/impartus/deleteImpartusUser",
      obj,
      this.allApiService.httpOptions
    );
  }
}
