import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AllApisService } from "../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class BookContentListService {
  baseurl = this.allApisService.baseurl + "/questions";
  searchObj = new searchKeys();
  constructor(
    private http: HttpClient,
    private allApisService: AllApisService
  ) {}

  updateEbook(obj: any) {
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/updateEbook",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateManual(obj){
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/updateManual",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateAudio(obj: any) {
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/updateAudio",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateVideo(obj: any) {
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/updateVideo",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateChapter(obj: any) {
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/updateChapter",
      obj,
      this.allApisService.httpOptions
    );
  }

  getBookContents(obj: any) {
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/get",
      obj,
      this.allApisService.httpOptions
    );
  }

  getBookChapters(obj: any) {
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/getChapters",
      obj,
      this.allApisService.httpOptions
    );
  }
  bookContentKeys() {
    return this.http.get<any>(
      this.allApisService.baseurl + "/books/keys",
      this.allApisService.httpOptions
    );
  }
  getClasses() {
    return this.http.get<any>(
      this.baseurl + "/getClass",
      this.allApisService.httpOptions
    );
  }
  saveExamPaper(reqObj) {
    return this.http.post<any>(
      this.baseurl + "/saveExamPaper",
      reqObj,
      this.allApisService.httpOptions
    );
  }
  getSubject() {
    return this.http.get<any>(
      this.baseurl + "/getSubject",
      this.allApisService.httpOptions
    );
  }
  updateEditLesson(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editLesson",
      obj,
      this.allApisService.httpOptions
    );
  }
  updateEditContent(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editContent",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateEditActivity(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editActivity",
      obj,
      this.allApisService.httpOptions
    );
  }

  deleteChapter(obj){
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/deleteChapter",
      obj,
      this.allApisService.httpOptions
    );
  }

  checkAssignedSchool(obj){
    return this.http.post<any>(
      this.allApisService.baseurl + "/books/checkSubject",
      obj,
      this.allApisService.httpOptions
    );
  }

  deleteLesson(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteLesson",
      obj,
      this.allApisService.httpOptions
    );
  }
  deleteContent(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteContent",
      obj,
      this.allApisService.httpOptions
    );
  }
  deleteActivity(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteActivity",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateLink(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editUrl",
      obj,
      this.allApisService.httpOptions
    );
  }
  getpdVideos() {
    return this.http.get<any>(
      this.baseurl + "/pdVideos",
      this.allApisService.httpOptions
    );
  }
}

export class searchKeys {
  std: string;
  subject: string;
  category: string;
  series: string;
  chapterNo: string;
  topic: string;
  examName: string;
  maxMarks: Number;
  maxHours: Number;
  questionGroups = [];
}
