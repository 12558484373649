import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import WebViewer from "@pdftron/pdfjs-express";
import { StudentService } from "../student/student.service";

@Component({
  selector: "app-pdfjs",
  templateUrl: "./pdfjs.component.html",
  styleUrls: ["./pdfjs.component.css"],
})
export class PdfjsComponent implements OnInit {
  pdfUrl: any;
  startTime: any;
  endTime: any;
  selectedStd: any;
  userDetails: any;
  timeDifferenceInSec: number;
  chatper: any;
  useageReport: any;

  @ViewChild("viewer", { static: true }) viewRef: ElementRef;

  constructor(private serv: StudentService) {}

  ngOnInit(): void {
    this.startTime = new Date(JSON.parse(localStorage.getItem("startTime")));
    // this.selectedStd = JSON.parse(localStorage.getItem("selectedSTD"));
    this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
    this.chatper = JSON.parse(localStorage.getItem("selectedCHAPTER"));
    console.log("time and class", this.startTime, this.selectedStd);
    console.log("current user", this.userDetails);
    console.log("chapter selected", this.chatper);
    this.pdfUrl =
      "https://symbiosis.s3.ap-south-1.amazonaws.com/Eupheus/karadi/Cat's%20Egg%20.pdf";
    this.pdfUrl = JSON.parse(localStorage.getItem("pdfUrl"));
    console.log("this pdf", this.pdfUrl);
    WebViewer(
      {
        licenseKey: "pYfOk72r0X7OjkkASEt7",
        path: "../../wv-resources/lib",
        //  useDownloader: false,
        initialDoc: this.pdfUrl,
      },
      this.viewRef.nativeElement
    ).then((instance) => {
      const { documentViewer, annotationManager } = instance.Core;

      instance.UI.setTheme("dark");
      instance.UI.disableElements(["ribbons", "toggleNotesButton"]);
      instance.UI.setToolbarGroup("toolbarGroup-View");
      instance.UI.disableElements([
        "viewControlsButton",
        "viewControlsOverlay",
        "panToolButton",
        "selectToolButton",
        "textSelectButton",
      ]);
      // textSelectButton
      instance.UI.disableElements(["ribbons"]);
      instance.UI.setLayoutMode(instance.UI.LayoutMode.Single);
      instance.UI.disableElements(["leftPanel", "leftPanelButton"]);
      instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
      instance.UI.enableElements(["fullscreenButton"]);
      //  instance.UI.enableElements(['zoomOverlay','zoomOutButton' , 'zoomInButton']);
      var feature = instance.UI.Feature;

      // instance.UI.disableFeatures([feature.Download]);
    });
  }

  calculateTimeDifference() {
    this.endTime = new Date();
    const timeDifferenceInMilliseconds =
      this.endTime.getTime() - this.startTime.getTime();
    this.timeDifferenceInSec = timeDifferenceInMilliseconds / 1000;
    console.log("time in minture", this.timeDifferenceInSec);
    this.useageReport = {};
    this.useageReport["category"] = this.chatper.category;
    this.useageReport["session"] = this.chatper.session;
    this.useageReport["smSchoolId"] = this.userDetails.smSchoolId;
    this.useageReport["sourceId"] = this.userDetails.sourceId;
    this.useageReport["name"] =
      this.userDetails.firstName + " " + this.userDetails.lastName;
    this.useageReport["timeUsed"] = this.timeDifferenceInSec;
    this.useageReport["title"] =  this.chatper.title;
    this.useageReport["url"] = this.chatper.url;
    this.useageReport["lastSeenDate"] = new Date();
    console.log("this.useageReport", this.useageReport);
    this.serv.updateUseageReport(this.useageReport).subscribe((res) => {
      console.log("updated usage report", res);
    });
  }

  // This function will be called when the tab is closed or back button is pressed
  @HostListener("window:beforeunload", ["$event"])
  onBeforeUnload(event: any): void {
    // Call your function here
    this.myFunctionToExecuteOnUnload();
  }

  myFunctionToExecuteOnUnload() {
    // Implement your logic here
    console.log("Tab is closing or back button is pressed.");
    // You can perform additional actions as needed.
    this.calculateTimeDifference();
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event: any): void {
    // Handle the back button press here
    console.log("Back button pressed");
    // You can perform any specific action you need when the back button is pressed.
    this.calculateTimeDifference();
  }
}
