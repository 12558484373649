import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataTablesModule } from "angular-datatables";
import { HttpClientModule } from "@angular/common/http";
import { NotifierModule } from "angular-notifier";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { AuthenticationModule } from "./authentication/authentication.module";
import { FormsModule } from "@angular/forms";
import { SchoolInfoComponent } from "./school-info/school-info.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UiSwitchModule } from "ngx-ui-switch";
import { UserInfoComponent } from "./user-info/user-info.component";
import { SchoolInfoListComponent } from "./school-info-list/school-info-list.component";
import { UserListComponent } from "./user-list/user-list.component";
import { SchoolLicenceComponent } from "./school-licence/school-licence.component";
import { TeacherCredentialsComponent } from "./teacher-credentials/teacher-credentials.component";
import { ImportexcelComponent } from "./importexcel/importexcel.component";
import { FileUploadModule } from "ng2-file-upload";
import { StudentCredentialsComponent } from "./student-credentials/student-credentials.component";
import { PaymentModule } from "./payment/payment.module";
import { GoogleChartsModule } from "angular-google-charts";
import { MonthlyAttendenceComponent } from "./monthly-attendence/monthly-attendence.component";
import { ProfessionalDevelopmentComponent } from "./professional-development/professional-development.component";
import { PdVideosComponent } from "./pd-videos/pd-videos.component";
import { LiveClassAttendenceComponent } from "./live-class-attendence/live-class-attendence.component";
import { StudentImpartusMapComponent } from "./student-impartus-map/student-impartus-map.component";
import { SchoolSubjectAssignment } from "./school-subject-assignment/school-subject-assignment";
import { IntroPageComponent } from "./intro-page/intro-page.component";
import { ActivityDashboardComponent } from "./activity-dashboard/activity-dashboard.component";
import { ContentDashboardComponent } from "./content-dashboard/content-dashboard.component";
import { LessonDashboardComponent } from "./lesson-dashboard/lesson-dashboard.component";
import { TeacherDashboardComponent } from "./teacher-dashboard/teacher-dashboard.component";
import { StudentDashboardComponent } from "./student-dashboard/student-dashboard.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { SchoolContentMapComponent } from "./school-content-map/school-content-map.component";
import { ManageContentModule } from "./manage-content/manage-content.module";
import { NavigationBarModule } from "./navigation-bar/navigation-bar.module";
import { TranslatorModule } from "../translator/index";
import { CreateExamComponent } from "./create-exam/create-exam.component";
import { FetchQuestionsComponent } from "./fetch-questions/fetch-questions.component";
import { SelectQuestionsComponent } from "./select-questions/select-questions.component";
import { SaveExamComponent } from "./save-exam/save-exam.component";
import { AllPapersComponent } from "./all-papers/all-papers.component";
import { SchoolSubjectMapComponent } from "./school-subject-map/school-subject-map.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { PdfjsComponent } from './pdfjs/pdfjs.component';
import { Pdfjs1Component } from './pdfjs1/pdfjs1.component';
import { TeacherManualComponent } from './teacher-manual/teacher-manual.component';

@NgModule({
  declarations: [
    AppComponent,
    SchoolInfoComponent,
    SchoolContentMapComponent,
    SchoolSubjectMapComponent,
    UserInfoComponent,
    SchoolInfoListComponent,
    UserListComponent,
    SchoolLicenceComponent,
    AdminDashboardComponent,
    StudentDashboardComponent,
    TeacherDashboardComponent,
    ImportexcelComponent,
    LessonDashboardComponent,
    ContentDashboardComponent,
    ActivityDashboardComponent,
    IntroPageComponent,
    SchoolSubjectAssignment,
    StudentImpartusMapComponent,
    LiveClassAttendenceComponent,
    PdVideosComponent,
    ProfessionalDevelopmentComponent,
    StudentCredentialsComponent,
    TeacherCredentialsComponent,
    MonthlyAttendenceComponent,
    CreateExamComponent,
    FetchQuestionsComponent,
    SelectQuestionsComponent,
    SaveExamComponent,
    AllPapersComponent,
    PdfjsComponent,
    Pdfjs1Component,
    TeacherManualComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    AuthenticationModule,
    SharedModule,
    FormsModule,
    NavigationBarModule,
    DataTablesModule,
    ManageContentModule,
    FileUploadModule,
    GoogleChartsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    PaymentModule,
    NgMultiSelectDropDownModule.forRoot(),
    NotifierModule.withConfig({
      position: {
        vertical: { position: "top", distance: 12 },
        horizontal: { position: "right", distance: 12 },
      },
      behaviour: { onMouseover: "pauseAutoHide", stacking: 1, autoHide: 2000 },
    }),
    UiSwitchModule.forRoot({
      // size: 'small',
      color: "rgb(0, 189, 99)",
      switchColor: "#fff",
      defaultBgColor: "#ff0000",
      defaultBoColor: "#fff",
      // checkedLabel: 'on',
      // uncheckedLabel: 'off'
    }),
    TranslatorModule.forRoot({
      defaultLang: "en",
      storagePrefix: "translate-language",
    }),
    NgxQRCodeModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
