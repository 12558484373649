import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SmSignInComponent } from "./authentication/sm-sign-in/sm-sign-in.component";
import { SchoolInfoComponent } from "./school-info/school-info.component";
import { UserInfoComponent } from "./user-info/user-info.component";
import { SchoolInfoListComponent } from "./school-info-list/school-info-list.component";
import { UserListComponent } from "./user-list/user-list.component";
import { SchoolLicenceComponent } from "./school-licence/school-licence.component";
import { TeacherCredentialsComponent } from "./teacher-credentials/teacher-credentials.component";
import { ImportexcelComponent } from "./importexcel/importexcel.component";
import { StudentCredentialsComponent } from "./student-credentials/student-credentials.component";

import { MonthlyAttendenceComponent } from "./monthly-attendence/monthly-attendence.component";
import { LiveClassAttendenceComponent } from "./live-class-attendence/live-class-attendence.component";
import { PageNotFoundComponent } from "./shared/page-not-found/page-not-found.component";
import { SchoolSubjectAssignment } from "./school-subject-assignment/school-subject-assignment";
import { YtplayerComponent } from "./shared/ytplayer/ytplayer.component";
import { ProfessionalDevelopmentComponent } from "./professional-development/professional-development.component";
import { StudentImpartusMapComponent } from "./student-impartus-map/student-impartus-map.component";
import { StudentDashboardComponent } from "./student-dashboard/student-dashboard.component";
import { PdVideosComponent } from "./pd-videos/pd-videos.component";
import { ActivityDashboardComponent } from "./activity-dashboard/activity-dashboard.component";
import { ContentDashboardComponent } from "./content-dashboard/content-dashboard.component";
import { LessonDashboardComponent } from "./lesson-dashboard/lesson-dashboard.component";
import { TeacherDashboardComponent } from "./teacher-dashboard/teacher-dashboard.component";
import { MasterAdminComponent } from "./manage-content/master-admin/master-admin.component";
import { SchoolContentMapComponent } from "./school-content-map/school-content-map.component";
import { SignInComponent } from "./authentication/sign-in/sign-in.component";
import { IntroPageComponent } from "./intro-page/intro-page.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { OnlinePaymentComponent } from "./payment/online-payment/online-payment.component";
import { QuestionListComponent } from "./master-admin/question-list/question-list.component";
import { CreateExamComponent } from "./create-exam/create-exam.component";
import { FetchQuestionsComponent } from "./fetch-questions/fetch-questions.component";
import { SelectQuestionsComponent } from "./select-questions/select-questions.component";
import { SaveExamComponent } from "./save-exam/save-exam.component";
import { PdfjsComponent } from "./pdfjs/pdfjs.component";
import { Pdfjs1Component } from "./pdfjs1/pdfjs1.component";

export const routes: Routes = [
  // {path: 'liveClassSignIn',component:SigninWithSMComponent},
  { path: "", component: SignInComponent },
  { path: "pdf", component: PdfjsComponent },
  { path: "pdf1", component: Pdfjs1Component },
  // {path: '' , loadChildren:() => import('./home/home.module').then(m => m.HomeModule)},
  // {path: 'courses' , loadChildren:() => import('./manage-course/manage-course.module').then(m => m.MangeCourseModule)},
  {
    path: "registration",
    loadChildren: () =>
      import("./student-registration/student-registration.module").then(
        (m) => m.StudentRegistrationModule
      ),
  },
  {
    path: "payments",
    loadChildren: () =>
      import("./student-registration/student-registration.module").then(
        (m) => m.StudentRegistrationModule
      ),
  },
  // {
  //   path: "assessment",
  //   loadChildren: () =>
  //     import("./student-registration/student-registration.module").then(
  //       (m) => m.StudentRegistrationModule
  //     ),
  // },
  {
    path: "assessment",
    loadChildren: () =>
      import(
        "./assessment-administration/assessment-administration.module"
      ).then((m) => m.AssessmentAdministrationModule),
  },
  {
    path: "wardAssessment",
    loadChildren: () =>
      import("./assessment-ward/assessment-ward.module").then(
        (m) => m.AssessmentWardModule
      ),
  },
  {
    path: "uploads",
    loadChildren: () =>
      import("./student-registration/student-registration.module").then(
        (m) => m.StudentRegistrationModule
      ),
  },
  {
    path: "courses",
    loadChildren: () =>
      import("./student-registration/student-registration.module").then(
        (m) => m.StudentRegistrationModule
      ),
  },

  {
    path: "student-old",
    loadChildren: () =>
      import("./student-dash/student-dash.module").then(
        (m) => m.StudentDashModule
      ),
  },

  {
    path: "course-dash",
    loadChildren: () =>
      import("./course-dash/course-dash.module").then(
        (m) => m.CourseDashModule
      ),
  },
  // {path: '' , loadChildren:() => import('./course-dash/course-dash.module').then(m => m.CourseDashModule)},

  {
    path: "teacher",
    loadChildren: () =>
      import("./teacherDashboard/teacher-dash.module").then(
        (m) => m.TeachersDashModule
      ),
  },
  //from chrysalais
  { path: "payment/enroll", component: OnlinePaymentComponent },
  // {path: '', component:IntroPageComponent},
  { path: "sm-sign-in", component: SmSignInComponent },
  { path: "sign-in", component: SignInComponent },

  { path: "schoolInfo", component: SchoolInfoComponent },
  { path: "schoolMap", component: SchoolContentMapComponent },

  { path: "schoolInfoList", component: SchoolInfoListComponent },
  { path: "userInfo", component: UserInfoComponent },
  { path: "userList", component: UserListComponent },

  { path: "schoolLicenceInfo", component: SchoolLicenceComponent },

  {
    path: "importExl/module",
    component: ImportexcelComponent,
    data: { viewOption: "module" },
  },
  {
    path: "importExl/module-topic",
    component: ImportexcelComponent,
    data: { viewOption: "module-topic" },
  },
  {
    path: "importExl/book-content",
    component: ImportexcelComponent,
    data: { viewOption: "book-content" },
  },

  {
    path: "importExl/module-activity",
    component: ImportexcelComponent,
    data: { viewOption: "module-activity" },
  },
  {
    path: "importExl/pdVideos",
    component: ImportexcelComponent,
    data: { viewOption: "pdVideos" },
  },
  {
    path: "importExl/questionPaper",
    component: ImportexcelComponent,
    data: { viewOption: "paper" },
  },
  {
    path: "importExl/editQuestionPaper",
    component: ImportexcelComponent,
    data: { viewOption: "editPaper" },
  },

  //ppaper
  {
    path: "importExl/practicePaper",
    component: ImportexcelComponent,
    data: { viewOption: "practicePaper" },
  },
  // {path: 'dashboard/masterAdmin', component:MasterAdminComponent},
  {
    path: "masterAdmin",
    loadChildren: () =>
      import("./master-admin/master-admin.module").then(
        (m) => m.MasterAdminModule
      ),
  },
  { path: "hi", component: AdminDashboardComponent },

  { path: "dashboard/teacher", component: TeacherDashboardComponent },
  { path: "dashboard/lesson", component: LessonDashboardComponent },
  { path: "dashboard/question-list", component: QuestionListComponent },
  { path: "dashboard/create-exam", component: CreateExamComponent },
  { path: "dashboard/fetch-questions", component: FetchQuestionsComponent },
  { path: "dashboard/select-questions", component: SelectQuestionsComponent },
  { path: "dashboard/save-exam", component: SaveExamComponent },

  { path: "dashboard/content", component: ContentDashboardComponent },
  { path: "dashboard/activity", component: ActivityDashboardComponent },
  { path: "dashboard/pdVideos", component: PdVideosComponent },
  { path: "dashboard/student", component: StudentDashboardComponent },
  { path: "impartusMapping", component: StudentImpartusMapComponent },
  { path: "studentCredentials", component: StudentCredentialsComponent },

  {
    path: "dashboard/teacherProfessionalDevelopment",
    component: ProfessionalDevelopmentComponent,
    data: { viewOption: "Teacher" },
  },
  {
    path: "dashboard/parentVideos",
    component: ProfessionalDevelopmentComponent,
    data: { viewOption: "Parent" },
  },
  { path: "player", component: YtplayerComponent },
  { path: "schoolAssignedSubject", component: SchoolSubjectAssignment },
  { path: "workinprogress", component: PageNotFoundComponent },
  { path: "liveClassAttendence", component: LiveClassAttendenceComponent },
  {
    path: "importExl/teacher",
    component: ImportexcelComponent,
    data: { viewOption: "teacher" },
  },
  {
    path: "importExl/student",
    component: ImportexcelComponent,
    data: { viewOption: "student" },
  },
  { path: "monthlyAttendence", component: MonthlyAttendenceComponent },
  { path: "credentials", component: TeacherCredentialsComponent },
  {
    path: "student",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
  },
  {
    path: "teacher-old",
    loadChildren: () =>
      import("./teacher/teacher.module").then((m) => m.TeacherModule),
  },
  {
    path: "principal",
    loadChildren: () =>
      import("./principal-reports/principal-reports.module").then(
        (m) => m.PrincipalReportsModule
      ),
  },
  {
    path: "admin",
    loadChildren: () => import("./zoom/zoom.module").then((m) => m.ZoomModule),
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./super-admin/super-admin.module").then(
        (m) => m.SuperAdminModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
