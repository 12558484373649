import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { SignInModel } from "../models/signinModel";
import { AllApisService } from "../../all-apis.service";
import { NotifierService } from "angular-notifier";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-signin",
  templateUrl: "./sm-sign-in.component.html",
  styleUrls: ["./sm-sign-in.component.css", "../loginstyle.css"],
})
export class SmSignInComponent implements OnInit {
  validLoginId: boolean;
  showLoader: boolean = true;
  showLoader1: boolean = true;
  schoolInfo: any;
  loaderMessage: string = "";
  loaderMessage1: string = "";
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private allApies: AllApisService,
    private notifier: NotifierService
  ) {} //, private global:OneStorage

  signInModel = new SignInModel();
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;
  //server Data
  sData: Object;
  role: string;
  path: string;
  ngOnInit() {
    this.showLoader = true;
    if (this.activeRoute.queryParams) {
      this.loaderMessage1 = "Verifying login details..";
      this.activeRoute.queryParams.subscribe((params) => {
        console.log("params.auth_token", params.auth_token);
        console.log("params.smitra_id", params.smitra_id);
        if (
          params.smitra_id &&
          localStorage.getItem("userDetail") &&
          localStorage.getItem("userDetail") != "undefined" &&
          localStorage.getItem("userDetail") != ""
        ) {
          let loginUser = JSON.parse(localStorage.getItem("userDetail"));
          if (loginUser.userName == params.smitra_id) {
            console.log("repeat");
            this.role = localStorage.getItem("role");
            //Initilization Token
            let headers = new HttpHeaders({
              Authorization: "Bearer " + this.allApies.getToken(),
            });
            this.allApies.httpOptions.headers = headers;
            this.callAfterSignIn(params);
          } else {
            this.removeLocalStorage();
          }
        } else {
          this.removeLocalStorage();
        }
        console.log("params", params.addSchoolId);

        if (params.auth_token) {
          this.userService
            .signInWithSMToken(
              params.auth_token,
              params.smitra_id,
              params.student_id,
              params.user_source_id
            )
            .subscribe(
              (res) => {
                const isSucess = res.msg;
                console.log(res);
                //  console.log('token : ' + res.token);
                this.role = res.role;
                this.path = res.path;
                if (res.token !== null) {
                  this.saveLocalStorage(res);
                  localStorage.setItem("token", res.token);
                  this.schoolInfo = res.schoolInfo;
                  localStorage.setItem(
                    "schoolInfo",
                    JSON.stringify(res.schoolInfo)
                  );
                  localStorage.setItem(
                    "userDetail",
                    JSON.stringify(res.userDetail)
                  );
                  if (res.studentInfo) {
                    localStorage.setItem(
                      "studentInfo",
                      JSON.stringify(res.studentInfo)
                    );
                  }
                  if (res.teacherInfo) {
                    localStorage.setItem(
                      "teacherInfo",
                      JSON.stringify(res.teacherInfo)
                    );
                  }
                  if (res.studentDetail) {
                    localStorage.setItem(
                      "studentDetail",
                      JSON.stringify(res.studentDetail)
                    );
                  }
                  localStorage.setItem("role", res.role);
                  //Initilization Token
                  let headers = new HttpHeaders({
                    Authorization: "Bearer " + this.allApies.getToken(),
                  });
                  this.allApies.httpOptions.headers = headers;
                  console.log(this.allApies.httpOptions);
                } else {
                  this.notifier.notify("error", res.message);
                }
                this.callAfterSignIn(params);
              },
              (error) => {
                console.log("Error", error);
                this.notifier.notify("info", "Oops! Network Error.");
              }
            );
        } else if (params.token && params.studentId) {
          this.userService
            .signInWithMobileToken(params.token, params.studentId)
            .subscribe(
              (res) => {
                const isSucess = res.msg;
                console.log(res);
                //  console.log('token : ' + res.token);
                this.role = res.role;
                if (res.token !== null) {
                  this.saveLocalStorage(res);
                  localStorage.setItem("token", res.token);
                  this.schoolInfo = res.schoolInfo;
                  localStorage.setItem(
                    "schoolInfo",
                    JSON.stringify(res.schoolInfo)
                  );
                  localStorage.setItem(
                    "userDetail",
                    JSON.stringify(res.userDetail)
                  );
                  if (res.studentDetail) {
                    localStorage.setItem(
                      "studentDetail",
                      JSON.stringify(res.studentDetail)
                    );
                  }
                  if (res.studentInfo) {
                    localStorage.setItem(
                      "studentInfo",
                      JSON.stringify(res.studentInfo)
                    );
                  }
                  if (res.teacherInfo) {
                    localStorage.setItem(
                      "teacherInfo",
                      JSON.stringify(res.teacherInfo)
                    );
                  }

                  localStorage.setItem("role", res.role);

                  //Initilization Token
                  let headers = new HttpHeaders({
                    Authorization: "Bearer " + this.allApies.getToken(),
                  });
                  this.allApies.httpOptions.headers = headers;
                  console.log(this.allApies.httpOptions);
                  this.callAfterSignIn(params);
                } else {
                  this.notifier.notify("error", res.message);
                  this.showLoader = false;
                }
              },
              (error) => {
                console.log("Error", error);
                this.notifier.notify("info", "Oops! Network Error.");
                this.showLoader = false;
              }
            );
        } else {
          console.log("loader", this.showLoader);
          this.showLoader = false;
        }
      });
    } else {
      console.log("loader", this.showLoader);
      this.showLoader = false;
    }
  }

  removeLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("schoolInfo");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("studentDetail");
    localStorage.removeItem("role");
  }

  callAfterSignIn(params) {
    localStorage.removeItem("isfo");
    localStorage.removeItem("karadi");
    localStorage.removeItem("ck");
    localStorage.removeItem("sdg");
    localStorage.removeItem("eng");
    localStorage.removeItem("sci");
    localStorage.removeItem("mat");
    localStorage.removeItem("cksubjects");
    localStorage.removeItem("ckgrade");
    localStorage.removeItem("edumaxdoc");
    localStorage.removeItem("wordsMobile");
    console.log("loader", this.showLoader);
    this.showLoader = false;
    var prefix = "";
    // if(this.schoolInfo && this.schoolInfo.zoomPaid) {
    //   prefix = "/zoom"
    // }
    console.log("came in let print param");
    console.log("came in let print param", params);
    if (params.karadi) {
      localStorage.setItem("karadi", "Y");
      this.router.navigate(["/student/dashboard/companion"], {
        queryParams: { subject: "karadi" },
      });
    } else if (params.sdg) {
      console.log("came in sdg");
      localStorage.setItem("sdg", "Y");
      this.router.navigate(["/student/dashboard/companionSDG"], {
        queryParams: { subject: "sdg" },
      });
    } else if (params.ck && !params.createTest && !params.viewTest) {
      console.log("params in ck", params);
      localStorage.setItem("ck", "Y");
      localStorage.setItem("cksubjects", JSON.stringify(params.cksubjects));
      localStorage.setItem("ckgrade", JSON.stringify(params.ckgrade));
      localStorage.setItem("cktoken", params.cktoken);
      if (this.role == "Principal") {
        var isAndroid = /Android/i.test(navigator.userAgent);
        var isIOS = /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
        console.log("navigation", isAndroid, isIOS);
        if (isAndroid) {
          // The website is being accessed on an Android device
          // this.onAndroid = false;
          if (params.std) {
            localStorage.setItem("ckstd", params.std);
          }
          this.router.navigate(["/student/dashboard/studiosDashboardCK"], {
            queryParams: { subject: "Glider" },
          });
        } else if (isIOS) {
          // The website is being accessed on an iOS device
          // this.onIos = false;
          if (params.std) {
            localStorage.setItem("ckstd", params.std);
          }
          this.router.navigate(["/student/dashboard/studiosDashboardCK"], {
            queryParams: { subject: "Glider" },
          });
        } else {
          console.log("yaha aaye hai navigate karne");
          this.router.navigate(["/student/dashboard/principalClasses"]);
        }
      } else {
        this.router.navigate(["/student/dashboard/studiosDashboardCK"], {
          queryParams: { subject: "Glider" },
        });
      }
    } else {
      if (this.role === "Parent") {
        console.log("parent");
        if (params.isfo) {
          localStorage.setItem("isfo", "Y");
        }
        if (params.karadi) {
          localStorage.setItem("karadi", "Y");
        }
        if (params.sdg) {
          localStorage.setItem("sdg", "Y");
        }
        if (params.eng) {
          localStorage.setItem("eng", "Y");
        }
        if (params.sci) {
          localStorage.setItem("sci", "Y");
        }
        if (params.mat) {
          localStorage.setItem("mat", "Y");
        }
        if (params.mbook) {
          localStorage.setItem("hideLeftNav", "Y");
          this.router.navigate(["/student/dashboard/studiosDashboard"]);
        }
        if (params.page_to) {
          this.router.navigate(["/student/dashboard/roboGard"]);
        }
        if (params.mathsbuddy) {
          this.router.navigate(["/student/dashboard/mathsbuddy"]);
        }
        if (params.words) {
          if (params.mobile) {
            localStorage.setItem("wordsMobile", "true");
          }
          this.router.navigate(["/student/dashboard/wordsWorth"]);
        }
      } else if (this.role === "Teacher") {
        if (params.mbook && params.createTest) {
          localStorage.setItem("hideLeftNav", "Y");
          this.router.navigate(["/admin/create-exam-2"]);
        } else if (params.mbook && params.viewTest) {
          localStorage.setItem("hideLeftNav", "Y");
          this.router.navigate(["/admin/all-papers-2"]);
        } else if (params.mbook) {
          localStorage.setItem("hideLeftNav", "Y");
          this.router.navigate(["/student/dashboard/studiosDashboard"]);
        } else if (params.page_to) {
          this.router.navigate(["/student/dashboard/roboGard"]);
        } else if (params.mathsbuddy) {
          localStorage.setItem("cktoken", params.cktoken);
          this.router.navigate(["/student/dashboard/mathsbuddy"]);
        } else if (params.words) {
          if (params.mobile) {
            localStorage.setItem("wordsMobile", "true");
          }
          this.router.navigate(["/student/dashboard/wordsWorth"]);
        } else if (params.edumaxdoc) {
          localStorage.setItem("hideLeftNav", "Y");
          localStorage.setItem("edumaxdoc", "Y");
          this.router.navigate(["/student/dashboard/edumaxDashboard"]);
        } else {
          this.router.navigate(["/admin/create-exam"]);
        }
      } else if (this.role === "Principal") {
        if (params.mbook) {
          if (params.std) {
            localStorage.setItem("std", params.std);
          }
          localStorage.setItem("hideLeftNav", "Y");
          this.router.navigate(["/student/dashboard/studiosDashboard"]);
        }
      } else if (params && params.path === "assessment") {
        if (params && params.result === "assessmentPerformance") {
          if (this.role === "Superadmin") {
            prefix = "/assessment";
            this.router.navigate(["/assessmentPerformance"]);
          } else if (this.role === "Admin") {
            prefix = "/assessment";
            this.router.navigate([prefix + "/assessmentPerformance"]);
          } else if (this.role === "Teacher") {
            prefix = "/assessment";
            this.router.navigate([prefix + "/assessmentPerformance"]);
          } else if (this.role === "Parent") {
            prefix = "/wardAssessment";
            this.router.navigate([prefix + "/studentResult"]);
          } else {
            prefix = "/wardAssessment";
            this.router.navigate([prefix + "/studentResult"]);
          }
        } else {
          if (this.role === "Superadmin") {
            this.router.navigate(["/adminDashBoard"]);
          } else if (this.role === "Admin") {
            prefix = "/assessment";
            if (
              params["assessmentdashBoard"] &&
              params["assessmentdashBoard"] != ""
            ) {
              this.router.navigate([prefix + "/adminDashBoard"]);
            } else if (
              params["assessmentperformance"] &&
              params["assessmentperformance"] != ""
            ) {
              this.router.navigate([prefix + "/assessmentPerformance"]);
            } else if (params["setquestion"] && params["setquestion"] != "") {
              this.router.navigate([prefix + "/question"]);
            } else if (params["editquestion"] && params["editquestion"] != "") {
              this.router.navigate([prefix + "/editAssessment"]);
            } else if (
              params["assignTeacherSubject"] &&
              params["assignTeacherSubject"] != ""
            ) {
              this.router.navigate([prefix + "/assignTeacherSubject"]);
            } else if (
              params["adminSettings"] &&
              params["adminSettings"] != ""
            ) {
              this.router.navigate([prefix + "/adminSettings"]);
            } else {
              this.router.navigate([prefix + "/adminDashBoard"]);
            }
          } else if (this.role === "Teacher") {
            prefix = "/assessment";
            if (
              params["assessmentdashBoard"] &&
              params["assessmentdashBoard"] != ""
            ) {
              this.router.navigate([prefix + "/teacherDashBoard"]);
            } else if (
              params["assessmentperformance"] &&
              params["assessmentperformance"] != ""
            ) {
              this.router.navigate([prefix + "/teacherAssessmentPerformance"]);
            } else if (params["setquestion"] && params["setquestion"] != "") {
              this.router.navigate([prefix + "/question"]);
            } else if (params["viewquestion"] && params["viewquestion"] != "") {
              this.router.navigate([prefix + "/teacherEditAssessment"]);
            } else {
              this.router.navigate([prefix + "/teacherDashBoard"]);
            }
          } else if (this.role === "Parent") {
            prefix = "/wardAssessment";
            if (
              params["assessmentdashBoard"] &&
              params["assessmentdashBoard"] != ""
            ) {
              this.router.navigate([prefix + "/studentDashBoard"]);
            } else if (
              params["studentresult"] &&
              params["studentresult"] != ""
            ) {
              this.router.navigate([prefix + "/studentResult"]);
            } else if (
              params["assessmentlist"] &&
              params["assessmentlist"] != ""
            ) {
              this.router.navigate([prefix + "/assessmentList"]);
            } else {
              this.router.navigate([prefix + "/studentDashBoard"]);
            }
          } else {
            prefix = "/wardAssessment";
            this.router.navigate([prefix + "/studentDashBoard"]);
          }
        }
      } else if (this.role === "Admin") {
        console.log("admin");
        if (params.mbook && params.createTest) {
          localStorage.setItem("hideLeftNav", "Y");
          this.router.navigate(["/admin/create-exam-2"]);
        } else if (params.mbook && params.viewTest) {
          localStorage.setItem("hideLeftNav", "Y");
          this.router.navigate(["/admin/all-papers-2"]);
        } else if (params.page_to) {
          // added for robo garden
          this.router.navigate(["/student/dashboard/roboGard"]);
        } else if (params.mathsbuddy) {
          this.router.navigate(["/student/dashboard/mathsbuddy"]);
        } else if (params.words) {
          if (params.mobile) {
            localStorage.setItem("wordsMobile", "true");
          }
          this.router.navigate(["/student/dashboard/wordsWorth"]);
        } else if (params.edumaxdoc) {
          localStorage.setItem("hideLeftNav", "Y");
          localStorage.setItem("edumaxdoc", "Y");
          this.router.navigate(["/student/dashboard/adminDocuments"]);
        } else {
          this.router.navigate([prefix + "/admin/create-exam"]);
        }
      } else if (params.path === "dailyContentUnlocked") {
        this.router.navigate([prefix + "/dailyContent"]);
      } else if (params.path === "dailyActivityResponse") {
        this.router.navigate([prefix + "/dailyResponse"]);
      } else if (params.path === "dailyStudentResponse") {
        this.router.navigate([prefix + "/dailyStudentCount"]);
      } else if (params.path === "dailyLiveClasses") {
        this.router.navigate([prefix + "/dailyLiveClass"]);
      }
    }

    this.showLoader = false;
  }

  validateInput() {
    var val = this.signInModel.emailId;
    if (val) {
      this.validLoginId = true;
    } else {
      this.validLoginId = false;
    }

    //return val.match(this.emailRegex) || val.match("other regex") && dosomething();
  }

  //form method (html)
  onLogin() {
    this.showLoader = true;
    this.userService.logMeInWithSm(this.signInModel).subscribe(
      (res) => {
        const isSucess = res.status;
        console.log("login response", res);
        //  console.log('token : ' + res.token);
        this.role = res.role;
        if (res.token !== null && this.signInModel.password !== null) {
          this.saveLocalStorage(res);
          localStorage.setItem("token", res.token);
          localStorage.setItem("userDetail", JSON.stringify(res.userDetail));
          if (res.studentDetail) {
            localStorage.setItem(
              "studentDetail",
              JSON.stringify(res.studentDetail)
            );
          }
          localStorage.setItem("role", res.role);
          //Initilization Token
          let headers = new HttpHeaders({
            Authorization: "Bearer " + this.allApies.getToken(),
          });
          this.allApies.httpOptions.headers = headers;
          console.log(this.allApies.httpOptions);
        } else {
          this.showLoader = false;
          this.notifier.notify("error", "Invalid E-mail/Password");
        }
      },
      (error) => {
        console.log("Error", error);
        this.showLoader = false;
        this.notifier.notify("info", "Oops! Network Error.");
      },
      () => {
        //To initilize Storage;
        this.callAfterSignIn(undefined);
      }
    );
  }
  backToHome() {
    console.log("clicked");
    //this.router.navigate(['']);
  }

  saveLocalStorage(res) {
    localStorage.removeItem("token");
    localStorage.removeItem("schoolInfo");
    localStorage.removeItem("teacherInfo");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("studentInfo");
    localStorage.removeItem("studentDetail");
    localStorage.removeItem("role");
    localStorage.setItem("token", res.token);
    this.schoolInfo = res.schoolInfo;

    localStorage.setItem("userDetail", JSON.stringify(res.userDetail));
    if (res.schoolInfo) {
      localStorage.setItem("schoolInfo", JSON.stringify(res.schoolInfo));
    }
    if (res.studentInfo) {
      localStorage.setItem("studentInfo", JSON.stringify(res.studentInfo));
    }
    if (res.teacherInfo) {
      localStorage.setItem("teacherInfo", JSON.stringify(res.teacherInfo));
    }
    if (res.studentDetail) {
      localStorage.setItem("studentDetail", JSON.stringify(res.studentDetail));
    }
    this.role = res.role;
    localStorage.setItem("role", res.role);
    //Initilization Token
    let headers = new HttpHeaders({
      Authorization: "Bearer " + this.allApies.getToken(),
    });
    this.allApies.httpOptions.headers = headers;
  }
}
