import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import WebViewer from "@pdftron/pdfjs-express";

@Component({
  selector: 'app-pdfjs1',
  templateUrl: './pdfjs1.component.html',
  styleUrls: ['./pdfjs1.component.css']
})
export class Pdfjs1Component implements OnInit {

  pdfUrl: any;

  @ViewChild("viewer", { static: true }) viewRef: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.pdfUrl =
      "https://symbiosis.s3.ap-south-1.amazonaws.com/Eupheus/karadi/Cat's%20Egg%20.pdf";
    this.pdfUrl = JSON.parse(localStorage.getItem("pdfUrl"));
    console.log("this pdf", this.pdfUrl);
    WebViewer(
      {
        licenseKey: "pYfOk72r0X7OjkkASEt7",
        path: "../../wv-resources/lib",
        //  useDownloader: false,
        initialDoc: this.pdfUrl,
      },
      this.viewRef.nativeElement
    ).then((instance) => {
      const { documentViewer, annotationManager } = instance.Core;

      instance.UI.setTheme("dark");
      instance.UI.disableElements(["ribbons", "toggleNotesButton"]);
      instance.UI.setToolbarGroup("toolbarGroup-View");
      instance.UI.disableElements([
        "viewControlsButton",
        "viewControlsOverlay",
        "panToolButton",
        "selectToolButton",
        "textSelectButton",
      ]);
      // textSelectButton
      instance.UI.disableElements(["ribbons"]);
      instance.UI.setLayoutMode(instance.UI.LayoutMode.Single);
      instance.UI.disableElements(["leftPanel", "leftPanelButton"]);
      instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
      instance.UI.enableElements(["fullscreenButton"]);
      //  instance.UI.enableElements(['zoomOverlay','zoomOutButton' , 'zoomInButton']);
      var feature = instance.UI.Feature;
      
      instance.UI.disableFeatures([feature.Print]);
    });
  }

}
