import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../services/user.service";
import { SignInModel } from "../models/signinModel";
import { AllApisService } from "../../all-apis.service";
import { NotifierService } from "angular-notifier";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { FormGroup } from "@angular/forms";
import { BindingFlags } from "@angular/compiler/src/core";
declare var $: any;

@Component({
  selector: "app-signin",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css", "../loginstyle.css"],
})
export class SignInComponent implements OnInit {
  validLoginId: boolean;
  withMobile: boolean = false;
  studentCount: number;
  isAdmin: boolean;
  loaderMessage: string;

  showLoader: boolean = false;

  otpSent = false;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private allApies: AllApisService,
    private notifier: NotifierService
  ) {} //, private global:OneStorage

  signInModel = new SignInModel();
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;
  //server Data
  sData: Object;
  role: string;
  params: any;
  enableMovement = true;
  ngOnInit() {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      ["Master Admin", "Viewer", "CMS Admin"].includes(
        localStorage.getItem("userType")
      )
    ) {
      let localRole = localStorage.getItem("userType");
      this.role = localRole;
      localStorage.setItem("role", localRole);
      localStorage.setItem("userType", localRole);
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "Editor"
    ) {
      this.role = "Editor";
      localStorage.setItem("role", "Editor");
      localStorage.setItem("userType", "Editor");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "FM"
    ) {
      this.role = "FM";
      localStorage.setItem("role", "FM");
      localStorage.setItem("userType", "FM");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "BookAllocator"
    ) {
      this.role = "BookAllocator";
      localStorage.setItem("role", "BookAllocator");
      localStorage.setItem("userType", "BookAllocator");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "EditorMaster"
    ) {
      this.role = "EditorMaster";
      localStorage.setItem("role", "EditorMaster");
      localStorage.setItem("userType", "EditorMaster");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "Rajpal"
    ) {
      this.role = "Rajpal";
      localStorage.setItem("role", "Rajpal");
      localStorage.setItem("userType", "Rajpal");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "Sahni"
    ) {
      this.role = "Sahni";
      localStorage.setItem("role", "Sahni");
      localStorage.setItem("userType", "Sahni");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "Meera"
    ) {
      this.role = "Meera";
      localStorage.setItem("role", "Meera");
      localStorage.setItem("userType", "Meera");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "words"
    ) {
      this.role = "words";
      localStorage.setItem("role", "words");
      localStorage.setItem("userType", "words");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "Admission Counceller"
    ) {
      this.role = "Admission Counceller";
      localStorage.setItem("role", "Admission Counceller");
      localStorage.setItem("userType", "Admission Counceller");
      this.router.navigate(["/registration/list"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "Admission Reviewer"
    ) {
      this.role = "Admission Reviewer";
      localStorage.setItem("role", "Admission Reviewer");
      localStorage.setItem("userType", "Admission Reviewer");
      this.router.navigate(["/registration/list"]);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "Student"
    ) {
      localStorage.setItem("role", "Student");
      localStorage.setItem("userType", "Student");
      this.router.navigate(["/course-dash/my-courses"]);
    }

    if (this.activeRoute.queryParams) {
      this.activeRoute.queryParams.subscribe((params) => {
        console.log("params.auth_token", params.auth_token);
        console.log("params.smitra_id", params.smitra_id);
        this.params = params;
        if (params.school_id) {
          localStorage.setItem("schoolId", params.school_id);
        }
        if (params.auth_token && params.smitra_id) {
          this.loaderMessage = "Verifying login details..";
          this.showLoader = true;
          if (params.smitra_id && localStorage.getItem("userDetail")) {
            let loginUser = JSON.parse(localStorage.getItem("userDetail"));
            if (loginUser.userName == params.smitra_id) {
              console.log("repeat");
              this.role = localStorage.getItem("role");
              if (
                localStorage.getItem("isAdmin") &&
                localStorage.getItem("isAdmin") == "true"
              ) {
                this.isAdmin = true;
              } else {
                this.isAdmin = false;
              }
              //Initilization Token
              let headers = new HttpHeaders({
                Authorization: "Bearer " + this.allApies.getToken(),
              });
              this.allApies.httpOptions.headers = headers;
              this.afterSignInPath();
            } else {
              this.callBackendSignIn(params);
            }
          } else {
            this.callBackendSignIn(params);
          }
        }
      });
    }
  }

  callBackendSignIn(params) {
    this.userService
      .signInWithSMToken(
        params.auth_token,
        params.smitra_id,
        params.student_id,
        params.user_source_id
      )
      .subscribe(
        (res) => {
          this.showLoader = false;
          this.saveLocalStorage(res);
        },
        (error) => {
          console.log("Error", error);
          this.showLoader = false;
        },
        () => {
          if (this.role) {
            console.log("enable", this.enableMovement);
            if (this.enableMovement) this.afterSignInPath();
            else this.notifier.notify("error", "No ward active currently.");
            this.enableMovement = true;
          }
        }
      );
  }

  loginWithMobile(flag: boolean = true) {
    this.withMobile = flag;
    return false;
  }

  validateInput() {
    var val = this.signInModel.emailId;
    if (val.match(this.emailRegex) || /^\d{10}$/.test(val)) {
      this.validLoginId = true;
    } else {
      this.validLoginId = true;
    }

    //return val.match(this.emailRegex) || val.match("other regex") && dosomething();
  }

  //form method (html)
  onLogin() {
    this.userService.logMeIn(this.signInModel).subscribe(
      (res) => {
        console.log("testing res", res);
        this.saveLocalStorage(res);
      },
      (error) => {
        console.log("Error", error);
        this.notifier.notify("info", "Oops! Something went wrong.");
      },
      () => {
        if (this.role) {
          console.log("enable", this.enableMovement);
          if (this.enableMovement) this.afterSignInPath();
          else this.notifier.notify("error", "No ward active currently.");
          this.enableMovement = true;
        }
      }
    );
  }

  backToHome() {
    console.log("clicked");
    this.router.navigate([""]);
  }

  saveLocalStorage(res) {
    console.log("res from login", res);
    localStorage.removeItem("token");
    localStorage.removeItem("schoolInfo");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("studentDetail");
    localStorage.removeItem("role");
    console.log("login response", res);
    this.role = null;
    this.role = res.role;
    let isAdminRole = false;
    if (this.role) {
      isAdminRole =
        this.role === "Admin" ||
        this.role === "Chrysallis Admin" ||
        this.role === "Chrysalis Admin";
      this.isAdmin = true;
    }
    ////////For Developemt Uncomment Below//////////////////
    // isAdminRole = !isAdminRole
    // this.isAdmin = true
    /////////////////////////////////////////////////////////
    if (res.token && res.token !== null) {
      localStorage.setItem("token", res.token);
      localStorage.setItem("schoolInfo", JSON.stringify(res.schoolInfo));
      localStorage.setItem("userDetail", JSON.stringify(res.userDetail));
      localStorage.setItem("user", JSON.stringify(res.user));
      if (res.studentDetail) {
        localStorage.setItem(
          "studentDetail",
          JSON.stringify(res.studentDetail)
        );
      }
      if (res.studentDetails && res.studentDetails.length) {
        this.studentCount = res.studentDetails.length;
        let studeAvail = false;
        for (let stud of res.studentDetails) {
          let studActive = true;
          if (
            stud &&
            stud.active !== null &&
            typeof stud.active !== "undefined" &&
            stud.active !== "undefined"
          ) {
            studActive = stud.active;
          }
          if (stud["active"]) {
            localStorage.setItem(
              "studentDetail",
              JSON.stringify(res.studentDetail)
            );
            studeAvail = true;
            break;
          }
        }
        localStorage.setItem(
          "studentDetails",
          JSON.stringify(res.studentDetails)
        );
        if (!studeAvail) {
          localStorage.clear();
          this.enableMovement = false;
          return;
        }
      }
      if (res.studentInfo) {
        localStorage.setItem("studentInfo", JSON.stringify(res.studentInfo));
      }
      if (res.teacherInfo) {
        localStorage.setItem("teacherInfo", JSON.stringify(res.teacherInfo));
      }
      if (res.smAuthToken) {
        localStorage.setItem("smAuthToken", res.smAuthToken);
      }
      localStorage.setItem("role", res.role);

      //Initilization Token
      let headers = new HttpHeaders({
        Authorization: "Bearer " + this.allApies.getToken(),
      });
      this.allApies.httpOptions.headers = headers;
      console.log(this.allApies.httpOptions);
    } else if (res.message) {
      this.notifier.notify("error", res.message);
    } else {
      this.notifier.notify("error", "Invalid Login-Id or Password");
    }
    // localStorage.setItem("isAdmin", this.isAdmin ? 'true' : 'false');
  }

  afterSignInPath() {
    this.showLoader = false;
    //To initilize Storage;
    console.log("this.isAdmin", this.isAdmin);
    localStorage.setItem("userType", this.role);
    localStorage.setItem("role", this.role);
    console.log("deciing rle", this.role);
    if (["Master Admin", "Viewer", "CMS Admin"].includes(this.role)) {
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "Eupheus Admin") {
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "Editor") {
      // BookAllocator
      console.log("came in editore");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "FM") {
      // BookAllocator
      console.log("came in FM");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "BookAllocator") {
      //
      console.log("came in BookAllocator");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "EditorMaster") {
      console.log("came in EditorMaster");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "Rajpal") {
      console.log("came in Rajpal");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "Meera") {
      console.log("came in Meera");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "words") {
      console.log("came in words");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (this.role === "Sahni") {
      console.log("came in Sahni");
      this.router.navigate(["/masterAdmin/dashboard"]);
    } else if (
      this.role === "Admission Counceller" ||
      this.role === "Admission Reviewer"
    ) {
      this.router.navigate(["/registration/list"]);
    } else if (this.role === "Parent") {
      if (this.studentCount > 1) {
        this.router.navigate(["/student/dashboard/studiosDashboard"]);
      } else {
        console.log(checkChrysalisUser());
        if (checkChrysalisUser())
          this.router.navigate(["/student/dashboard/studiosDashboard"]);
        else this.router.navigate(["/student/dashboard"]);
      }
    } else if (this.role === "Student") {
      this.router.navigate(["/course-dash/my-courses"]);
    } else if (this.role === "Teacher") {
      this.router.navigate(["/admin/create-exam"]);
    } else if (this.role === "Director") {
      if (this.params.path == "cart") this.router.navigate(["/principal/cart"]);
      else if (this.params.path == "transactionHistory")
        this.router.navigate(["/principal/transactionHistory"]);
    } else if (this.role === "Principal") {
      if (this.params.path === "dailyContentUnlocked") {
        this.router.navigate(["/principal/schoolReport"]);
      } else if (this.params.path === "dailyActivityResponse") {
        this.router.navigate(["/principal/schoolReport"]);
      } else if (this.params.path === "dailyStudentResponse") {
        this.router.navigate(["/principal/schoolReport"]);
      } else if (this.params.path === "dailyLiveClasses") {
        this.router.navigate(["/principal/schoolReport"]);
      } else if (this.params.path === "principalTeacherView") {
        this.router.navigate(["/principal/principalTeacherView"]);
      } else if (this.params.path === "schoolReport") {
        this.router.navigate(["/principal/schoolReport"]);
      } else this.router.navigate(["/principal/schoolInsight"]);
    } else if (this.isAdmin) {
      this.router.navigate(["/admin/create-exam"]);
      //// uncomment below for localhost/////////////
      //this.router.navigate(['/dashboard/admin']);
      /////////////////////////////////////////////////
      //////// comment below for localhost ////////////////
      //this.submitLoginForm('https://www.schoolmitra.com/users/login', 'post');
      //////////////////////////////////////////////
    } else {
      this.notifier.notify("info", "Unable to Authenticate, Please try again.");
    }
  }

  submitLoginForm(action, method) {
    const form = document.createElement("form");
    form.style.display = "none";
    form.method = method;
    form.action = action;
    form["accept-charset"] = "UTF-8";
    let input;
    let userName = this.signInModel.emailId;
    let passUser = this.signInModel.password;
    input = document.createElement("input");
    input.name = "user[smitra_id]";
    input.id = "userField";
    input.value = userName;
    form.appendChild(input);

    input = document.createElement("input");
    input.name = "user[password]";
    input.id = "passWordField";
    input.value = passUser;
    form.appendChild(input);

    input = document.createElement("input");
    input.name = "utf8";
    input.value = "✓";
    form.appendChild(input);

    input = document.createElement("input");
    input.name = "authenticity_token";
    input.value = "HoHQcX8yeMmVQWQ6woFKRVwm8VE6pvtel7wSAPRoUEU=";
    form.appendChild(input);

    document.body.appendChild(form);
    console.log("form", form);
    form.submit();
  }

  sendOtp() {
    this.userService.generateOtp(this.signInModel).subscribe((res) => {
      this.otpSent = true;
      this.notifier.notify("info", res.message);
      console.log(res);
    });
  }
}

function checkChrysalisUser() {
  let stud = localStorage.getItem("studentDetail");
  let school = localStorage.getItem("schoolInfo");
  let std = "";
  if (stud && stud !== "") {
    let studDet = JSON.parse(stud);
    std = studDet["student.std"];
  }
  let flag = true;
  if (school && school !== "") {
    let schoolDet = JSON.parse(school);

    schoolDet["standardList"].forEach((elem) => {
      if (
        elem["standard"] == std &&
        (typeof elem["contentStandard"] == "undefined" ||
          elem["contentStandard"] == "")
      ) {
        flag = false;
      }
    });
  }
  return flag;
}
