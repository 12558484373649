import { Component, OnInit } from "@angular/core";
import { QuestionListService } from "./question-list.service";
import { AllApisService } from "../../all-apis.service";
import { Router } from "@angular/router";
import { ConfirmDialogService } from "../../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../../shared/ytplayer/ytplayer.service";

declare var $: any;
@Component({
  selector: "app-question-list",
  templateUrl: "./question-list.component.html",
  styleUrls: ["./question-list.component.css"],
})
export class QuestionListComponent implements OnInit {
  questionList = [];
  stdList = [];
  categoryList = [];
  seriesList = [];
  subjectList = [];
  editId: any;
  editTitle: any;
  editSequence: any;
  searchObj = new searchKeys();
  popup = false;
  linkList: any;
  deletelesson: boolean = false;
  videoLink: any;
  safeVideo: any;
  checkLink: any;
  editLink: string;
  newLink: string;
  questionCol: any;
  editIndex: any;
  lessonIndex: any;
  deleteIndex: any;
  subjectMaps: any;
  sectionList: any[];
  allCategoryList: any;
  allSubjectList: any;
  questionKeys = [];
  constructor(
    private serv: QuestionListService,
    private allApiServ: AllApisService,
    private route: Router,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService
  ) {}

  ngOnInit(): void {
    this.getQuestionKeys();
    // this.getClassList();
    // this.getSubjectList();
  }

  populateTable() {
    let action_btn =
      '<span style="padding-left:10px;position:pointer;" class="text-danger links" title="Display Links"><i class="fas fa-eye"> </i> Links </span><span style="padding-left:10px;position:pointer;"  class="text-info edit" title="Edit Details"><i class="fas fa-edit"> </i></span> <span style="padding-left:10px;" class="delete text-danger" title="Delete"><i class="far fa-trash-alt" *ngIf="false"> </i></span> <span style="padding-left:10px;position:pointer;" class="text-info move" title="Move to contents"><i class="fas fa-arrow-right"> </i></span>';
    let action_btn_without_delete =
      '<span style="padding-left:10px;position:pointer;" class="text-danger links" title="Display Links"><i class="fas fa-eye"> </i> Links </span><span style="padding-left:10px;position:pointer;"  class="text-info edit" title="Edit Details"><i class="fas fa-edit"> </i></span> <span style="padding-left:10px;" class="delete text-danger" title="Delete"><i class="far fa-trash-alt" *ngIf="false"> </i></span> <span style="padding-left:10px;position:pointer;" class="text-info move" title="Move to contents"><i class="fas fa-arrow-right"> </i></span>';
    let self = this;
    if ($.fn.DataTable.isDataTable(".example1")) {
      $(".example1").DataTable().clear().destroy();
    }
    $(".showhideTable1").css("display", "block");
    const example = $(".example1").DataTable({
      data: this.questionList,
      columns: [
        { data: "series", defaultContent: "<i>Not Set</i>" },
        { data: "subject", defaultContent: "<i>Not Set</i>" },
        { data: "std", defaultContent: "<i>Not Set</i>" },
        { data: "topic", defaultContent: "<i>Not Set</i>" },
        {
          data: null,
          className: "center",
          render: function (data) {
            if (data.chapterNo && data.chapterName) {
              return `${data.chapterNo}: ${data.chapterName}`;
            } else if (data.chapterNo) {
              return `${data.chapterNo}`;
            } else if (data.chapterName) {
              return `${data.chapterName}`;
            } else {
              return "";
            }
          },
          defaultContent: "",
        },
        { data: "questionType", defaultContent: "<i>Not Set</i>" },
        // { data: "questionText", defaultContent: "<i>Not Set</i>" },
        {
          data: null,
          className: "center",
          render: function (data) {
            if (data.isImage === false) {
              return data.questionText;
            }
            if (data.isImage === true) {
              let action_btn_question =
                '<span class="question"><i style="padding-left:10px;position:pointer;"  class="fas fa-eye fa-2x text-info" title="View Answer"></i></span> ';
              let out = data.questionText + action_btn_question;
              return out;
            }
          },
          defaultContent: "<i>Not Set</i>",
        },
        {
          data: null,
          className: "center",
          render: function (data) {
            if (data.options && data.options.length > 0) {
              let retStr = "";
              data.options.forEach((element, i) => {
                if (element.optionText.includes("http")) {
                  if (i == 0) {
                    let action_btn_option =
                      '<span class="option1"><i style="padding-left:10px;position:pointer;"  class="fas fa-eye fa-2x text-info" title="View Answer"></i></span> ';
                    retStr += `${element.optionNo}:`;
                    retStr += action_btn_option;
                    retStr += `<br>`;
                  }
                  if (i == 1) {
                    let action_btn_option =
                      '<span class="option2"><i style="padding-left:10px;position:pointer;"  class="fas fa-eye fa-2x text-info" title="View Answer"></i></span> ';
                    retStr += `${element.optionNo}:`;
                    retStr += action_btn_option;
                    retStr += `<br>`;
                  }
                  if (i == 2) {
                    let action_btn_option =
                      '<span class="option3"><i style="padding-left:10px;position:pointer;"  class="fas fa-eye fa-2x text-info" title="View Answer"></i></span> ';
                    retStr += `${element.optionNo}:`;
                    retStr += action_btn_option;
                    retStr += `<br>`;
                  }
                  if (i == 3) {
                    let action_btn_option =
                      '<span class="option4"><i style="padding-left:10px;position:pointer;"  class="fas fa-eye fa-2x text-info" title="View Answer"></i></span> ';
                    retStr += `${element.optionNo}:`;
                    retStr += action_btn_option;
                    retStr += `<br>`;
                  }
                } else {
                  retStr += `${element.optionNo}: ${element.optionText} <br>`;
                }
              });
              return retStr;
            } else {
              return "";
            }
          },
          defaultContent: "",
        },
      ],
      dom: "Bfrtip",
      //   buttons: [
      //     {
      //       extend: 'excel',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'csv',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'pdf',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   }
      // ],
      order: [[0, "asc"]],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".example1 tbody ").on("click", "tr span.option1", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      var actualData = self.questionList[data];
      var index = 0;
      self.questionCol = actualData.options[index];
      $("#PictureModelOption").modal("show");
    });
    $(".example1 tbody ").on("click", "tr span.option2", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      var actualData = self.questionList[data];
      var index = 1;
      self.questionCol = actualData.options[index];
      $("#PictureModelOption").modal("show");
    });
    $(".example1 tbody ").on("click", "tr span.option3", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      var actualData = self.questionList[data];
      var index = 2;
      self.questionCol = actualData.options[index];
      $("#PictureModelOption").modal("show");
    });
    $(".example1 tbody ").on("click", "tr span.option4", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      var actualData = self.questionList[data];
      var index = 3;
      self.questionCol = actualData.options[index];
      $("#PictureModelOption").modal("show");
    });
    $(".example1 tbody ").on("click", "tr span.question", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      var actualData = self.questionList[data];
      self.questionCol = actualData;
      $("#PictureModel").modal("show");
    });
    $(".example1 tbody ").on("click", "tr span.move", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.navigateToContents(data);
    });
    $(".example1 tbody").on("click", "tr span.edit", function () {
      console.log("edit............");
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.editLessonData(self.questionList[data]);
    });
    $(".example1 tbody").on("click", "tr span.delete", function () {
      console.log("edit............");
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.deleteIndex = data;
      self.deleteLessonData(self.questionList[data]);
    });
    $(".example1 tbody ").on("click", "tr span.links", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.displayLinks(data);
    });
  }

  done() {
    $("#PictureModel").modal("hide");
  }
  done1() {
    $("#PictureModelOption").modal("hide");
  }

  deleteLessonData(data: any) {
    var obj = {};
    obj["id"] = data._id;
    let self = this;

    this.confirmBox.confirmThis(
      "Are you sure you want to delete the lesson",
      "Delete Lesson ?",
      function () {
        self.serv.deleteLesson(obj).subscribe((res) => {
          self.notifier.notify("success", "Lesson deleted succesfully");
          self.questionList.splice(self.deleteIndex, 1);
          self.populateTable();
        });
      },
      function () {}
    );
  }

  getQuestionKeys() {
    this.serv.getQuestionKeys().subscribe((res) => {
      console.log("keys", res);
      this.questionKeys = res.result;
      if (this.questionKeys && this.questionKeys.length) {
        this.stdList = this.questionKeys.map((a) => a.std);
        this.stdList = [...new Set(this.stdList)];
      }
    });
  }

  editLessonData(data: any) {
    console.log("data", data);
    this.editId = data._id;
    this.editTitle = data.title;
    this.editSequence = data.sequence;
    $("#editModel").modal("show");
  }

  updateEditDetails() {
    var obj = {};

    obj["id"] = this.editId;
    obj["editTitle"] = this.editTitle;
    obj["editSequence"] = this.editSequence;

    this.serv.updateEditLesson(obj).subscribe((res) => {
      console.log("lession", res);
      if (res.status == true) {
        $("#editModel").modal("hide");
      }

      this.getQuestionList();
    });
  }

  getClassList() {
    this.serv.getClasses().subscribe((res) => {
      this.stdList = res.result;
      console.log("this.stdList", this.stdList);
    });
  }

  getSubjectList() {
    this.serv.getSubject().subscribe((res) => {
      console.log("Subjects", res);
      var check = {};
      this.allSubjectList = res.subjects;
      this.allCategoryList = res.categoryList;
    });
  }

  updateCategoryList() {
    if (
      this.searchObj.subject &&
      this.allCategoryList &&
      this.allCategoryList.length
    ) {
      this.categoryList = this.allCategoryList.filter(
        (a) => a.subject.toUpperCase() == this.searchObj.subject.toUpperCase()
      );
    }
    this.categoryList = [];
    if (
      this.questionKeys &&
      this.questionKeys.length &&
      this.searchObj.subject
    ) {
      let filteredKeys = this.questionKeys.filter(
        (a) => a.subject == this.searchObj.subject
      );
      this.categoryList = filteredKeys.map((e) => e.subject);
      this.categoryList = [...new Set(this.categoryList)];
    }
  }

  updateSeriesList() {
    if (
      this.searchObj.subject &&
      this.allCategoryList &&
      this.allCategoryList.length
    ) {
      this.categoryList = this.allCategoryList.filter(
        (a) => a.subject.toUpperCase() == this.searchObj.subject.toUpperCase()
      );
    }
    this.seriesList = [];
    if (
      this.questionKeys &&
      this.questionKeys.length &&
      this.searchObj.subject
    ) {
      let filteredKeys = this.questionKeys.filter(
        (a) => a.subject == this.searchObj.subject
      );
      this.seriesList = filteredKeys.map((e) => e.series);
      this.seriesList = [...new Set(this.seriesList)];
    }
  }

  updateSubject() {
    this.subjectList = [];
    if (this.questionKeys && this.questionKeys.length && this.searchObj.std) {
      let filteredKeys = this.questionKeys.filter(
        (a) => a.std == this.searchObj.std
      );
      this.subjectList = filteredKeys.map((e) => e.subject);
      this.subjectList = [...new Set(this.subjectList)];
    }
  }

  getQuestionList() {
    this.serv.getQuestions(this.searchObj).subscribe((res) => {
      console.log("lession", res);
      if (res.status) {
        this.questionList = res["result"];
        this.populateTable();
      }
    });
  }
  navigateToContents(evt: any) {
    this.allApiServ.lessonInfo = this.questionList[evt];
    localStorage.setItem("moduleId", this.questionList[evt]["_id"]);
    this.route.navigate(["/dashboard/content"]);
  }

  displayLinks(evt: any) {
    this.linkList = this.questionList[evt]["links"];
    this.lessonIndex = evt;
    // this.popup=true;
    //this.populateTable2();
    $("#errorModal").modal("show");
  }

  displayModel(evt: any) {
    // window.location.href=this.linkList[evt]['url'];
    console.log("evt", evt);
    $("#errorModal").modal("hide");
    this.checkLink = this.linkList[evt]["url"];
    this.ytServ.confirmThis(this.checkLink);
  }

  backClicked() {
    this.route.navigate(["/masterAdmin/dashboard"]);
  }

  populateTable2() {
    let action_btn =
      '<i style="padding-right:30px,position:pointer;" class="fas fa-eye  text-danger links" title="Display Links"></i>  <i style="padding-left:30px,position:pointer;" class="fas fa-arrow-right  text-info move" title="Move to contents"></i>';
    let self = this;
    if ($.fn.DataTable.isDataTable(".example2")) {
      $(".example2").DataTable().clear().destroy();
    }
    $(".showhideTable2").css("display", "block");
    const example = $(".example2").DataTable({
      data: this.linkList,
      columns: [
        { data: "title", defaultContent: "<i>Not Set</i>" },
        { data: "url", defaultContent: "<i>Not Set</i>" },
        { data: "contentType", defaultContent: "<i>Not Set</i>" },
      ],
      // dom: 'Bfrtip',

      order: [],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
  }

  updateLink() {
    console.log("the link has been updated to", this.newLink);
    this.linkList[this.editIndex]["url"] = this.newLink;
    console.log(this.linkList[this.editIndex]["url"]);
    var obj = {
      standard: this.questionList[this.lessonIndex]["standard"],
      moduleId: this.questionList[this.lessonIndex]["moduleId"],
      version: this.questionList[this.lessonIndex]["version"],
      variant: this.questionList[this.lessonIndex]["variant"],
      subject: this.questionList[this.lessonIndex]["subject"],
      title: this.linkList[this.editIndex]["title"],
      url: this.newLink,
    };
    $("#editModal").modal("hide");
    this.serv.updateLink(obj).subscribe((res) => {
      if (res["status"]) {
        this.notifier.notify("success", "Link has been updated successfully");
      } else {
        this.notifier.notify("error", "Link update failed");
      }
    });
  }

  editModel(evt: any) {
    $("#errorModal").modal("hide");
    this.editLink = this.linkList[evt]["url"];
    this.editIndex = evt;
    $("#editModal").modal("show");
  }
}

export class searchKeys {
  std: string;
  subject: string;
  category: string;
  series: string;
}
